<template>
	<div>
		<a-spin :spinning="loading">
			<div style="height: 800px; background: #FFFFFF;"></div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false
			}
		},
		created(){
			if(!this.$route.query.auth_code){
				this.$message.error('授权回调出错了');
				this.refreshPage('/miniapp');
			}else{
				let  auth_code = this.$route.query.auth_code;
				this.loading = true;
				this.$http.papi('platform/miniapp/callback',{
					auth_code:auth_code
				}).then(res=>{
					this.loading =false;
					this.refreshPage('/miniapp');
				}).catch(res=>{
					this.loading = false;
				});
				
			}	
		},
		methods:{
			
		}
	}
</script>

<style>
	
</style>